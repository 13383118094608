
import { Options, Vue } from 'vue-class-component';
import { Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        Attention, CheckOne
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'user'
        }
    },
    data(){
        return{
            formData: {
                name: '',
                avatar: '',
                email: '',
                phone: '',
                role: '',
                password: '',
                repeadPassword: ''
            },
            formMsg: {
                name: '',
                email: '',
                phone: ''
            },
            roles: [],
            btnLoading: false,
            notification: {
                show: false,
                title: '',
                content: '',
                type: 'error'
            }
        }
    },
    watch: {
        data(){
            if(this.data) this.formData = this.data;
        }
    },
    mounted(){
        // if(this.data) this.formData = this.data;
        if(this.type === 'user') this.getRolesGuard('user_api');
        else this.getRolesGuard('admin_api');
    },
    methods: {
        resetData(){
            this.formData = this.data
        },
        checkFormData(){
            const formData = this.formData;
        },
        checkExist(label: string, value: string){
            const type: string = this.data ? 'update' : 'create';
            const id: number = this.data ? this.data.id : 0;
            if(this.type === 'user'){
                this.$api.checkUserRepeat(type, label, value, id).then((res: any) => {
                    if(label === 'name') this.formMsg.name = res.result ? '用户名已存在！' : '';
                    if(label === 'email') this.formMsg.email = res.result ? '邮箱已存在！' : '';
                    if(label === 'phone') this.formMsg.phone = res.result ? '手机号已存在！' : '';
                })
            } else {
                this.$api.checkAdminRepeat(type, label, value, id).then((res: any) => {
                    if(label === 'name') this.formMsg.name = res.result ? '用户名已存在！' : '';
                    if(label === 'email') this.formMsg.email = res.result ? '邮箱已存在！' : '';
                    if(label === 'phone') this.formMsg.phone = res.result ? '手机号已存在！' : '';
                })
            }
        },
        getRolesGuard(guard: string){
            this.$api.getRolesByGuard(guard).then((res: any) => {
                console.log(res)
                if(res.data.length === 0) this.roles = [{name: '无'}];
                else this.roles = res.data;
            })
        },
        chooseRole(index: number){
            this.formData.role = this.roles[index]
        },
        submit(){
            this.btnLoading = true;
            if(this.type === 'user'){
                if(this.data){
                    console.log('updateUserInfoById')
                    this.$api.updateUserInfoById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success'){
                            this.showNotification('SUCCESS', '用户信息更新成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                } else {
                    console.log('createNewUser')
                    this.$api.createNewUser(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success') {
                            this.showNotification('SUCCESS', '用户创建成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                }
            } else {
                if(this.data){
                    console.log('updateAdminInfoById')
                    this.$api.updateAdminInfoById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success'){
                            this.showNotification('SUCCESS', '管理员信息更新成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                } else {
                    console.log('createNewAdmin')
                    this.$api.createNewAdmin(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success') {
                            this.showNotification('SUCCESS', '管理员创建成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                }
            }
        },
        showNotification(type: string, content: string){
            this.notification = {
                show: true,
                title: type,
                content: content,
                type: type === 'ERROR' ? 'error' : 'SUCCESS'
            };
        }
    }
})

export default class UserForm extends Vue {}
