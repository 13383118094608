<template>
    <div class="form-container">
        <div class="form-head">
            <h4>{{ title }}</h4>
        </div>
        <div class="form-body">
            <div class="form-item">
                <div class="label">
                    <div class="label-container">
                        <span>用户名</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item">
                    <n-input
                        v-model:value="formData.name"
                        @blur="checkExist('name', formData.name)"
                        :msg="formMsg.name"
                        placeholder="请输入用户名"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="label">
                    <div class="label-container">
                        <span>头像</span>
                    </div>
                </div>
                <div class="item">
                    <n-upload  v-model:src="formData.avatar" />
                </div>
            </div>
            <div class="form-item">
                <div class="label">
                    <div class="label-container">
                        <span>邮箱</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item">
                    <n-input
                        v-model:value="formData.email"
                        type="email"
                        placeholder="请输入邮箱"
                        @blur="checkExist('email', formData.email)"
                        :msg="formMsg.email"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="label">
                    <div class="label-container">
                        <span>手机号</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item">
                    <n-input
                        v-model:value="formData.phone"
                        type="number"
                        placeholder="请输入手机号"
                        :maxlength="11"
                        @blur="checkExist('phone', formData.phone)"
                        :msg="formMsg.phone"
                    />
                </div>
            </div>

            <div class="form-item" v-if="type === 'admin'">
                <div class="label">
                    <div class="lable-container">
                        <span>角色</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item checkbox-group">
                    <n-select
                        placeholder="请选择角色"
                        v-model:value="formData.role.name"
                    >
                        <template v-for="(item, index) in roles" :key="index">
                            <n-select-option 
                                :label="item.name" :index="index"
                                :checked="formData.role.name === item.name"
                                @choose="chooseRole"
                            />
                        </template>
                    </n-select>
                </div>
            </div>

            <template v-if="!data">
                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>密码</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input v-model:value="formData.password" type="password" placeholder="请输入密码" showPassword/>
                    </div>
                </div>
                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>确认密码</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input v-model:value="formData.repeadPassword" type="password" placeholder="请再次输入密码" showPassword/>
                    </div>
                </div>
            </template>


        </div>
        <div class="form-bottom">
            <n-button class="btn" size="large" @click="resetData" round>重 置</n-button>
            <n-button class="btn"
                size="large" type="primary"
                :loading="btnLoading"
                @click="submit" round
            >确 定</n-button>
        </div>
        <n-notification
            v-model:show="notification.show"
            :title="notification.title"
            :content="notification.content"
        >
            <template v-slot:icon>
                <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
                <check-one v-else theme="outline" size="24" fill="#00c12b"/>
            </template>
        </n-notification>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        Attention, CheckOne
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'user'
        }
    },
    data(){
        return{
            formData: {
                name: '',
                avatar: '',
                email: '',
                phone: '',
                role: '',
                password: '',
                repeadPassword: ''
            },
            formMsg: {
                name: '',
                email: '',
                phone: ''
            },
            roles: [],
            btnLoading: false,
            notification: {
                show: false,
                title: '',
                content: '',
                type: 'error'
            }
        }
    },
    watch: {
        data(){
            if(this.data) this.formData = this.data;
        }
    },
    mounted(){
        // if(this.data) this.formData = this.data;
        if(this.type === 'user') this.getRolesGuard('user_api');
        else this.getRolesGuard('admin_api');
    },
    methods: {
        resetData(){
            this.formData = this.data
        },
        checkFormData(){
            const formData = this.formData;
        },
        checkExist(label: string, value: string){
            const type: string = this.data ? 'update' : 'create';
            const id: number = this.data ? this.data.id : 0;
            if(this.type === 'user'){
                this.$api.checkUserRepeat(type, label, value, id).then((res: any) => {
                    if(label === 'name') this.formMsg.name = res.result ? '用户名已存在！' : '';
                    if(label === 'email') this.formMsg.email = res.result ? '邮箱已存在！' : '';
                    if(label === 'phone') this.formMsg.phone = res.result ? '手机号已存在！' : '';
                })
            } else {
                this.$api.checkAdminRepeat(type, label, value, id).then((res: any) => {
                    if(label === 'name') this.formMsg.name = res.result ? '用户名已存在！' : '';
                    if(label === 'email') this.formMsg.email = res.result ? '邮箱已存在！' : '';
                    if(label === 'phone') this.formMsg.phone = res.result ? '手机号已存在！' : '';
                })
            }
        },
        getRolesGuard(guard: string){
            this.$api.getRolesByGuard(guard).then((res: any) => {
                console.log(res)
                if(res.data.length === 0) this.roles = [{name: '无'}];
                else this.roles = res.data;
            })
        },
        chooseRole(index: number){
            this.formData.role = this.roles[index]
        },
        submit(){
            this.btnLoading = true;
            if(this.type === 'user'){
                if(this.data){
                    console.log('updateUserInfoById')
                    this.$api.updateUserInfoById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success'){
                            this.showNotification('SUCCESS', '用户信息更新成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                } else {
                    console.log('createNewUser')
                    this.$api.createNewUser(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success') {
                            this.showNotification('SUCCESS', '用户创建成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                }
            } else {
                if(this.data){
                    console.log('updateAdminInfoById')
                    this.$api.updateAdminInfoById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success'){
                            this.showNotification('SUCCESS', '管理员信息更新成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                } else {
                    console.log('createNewAdmin')
                    this.$api.createNewAdmin(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        if(res.msg === 'success') {
                            this.showNotification('SUCCESS', '管理员创建成功！');
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 3000)
                        } else {
                            this.showNotification('ERROR', '接口报错，请检查后端接口！');
                        }
                    })
                }
            }
        },
        showNotification(type: string, content: string){
            this.notification = {
                show: true,
                title: type,
                content: content,
                type: type === 'ERROR' ? 'error' : 'SUCCESS'
            };
        }
    }
})

export default class UserForm extends Vue {}
</script>

<style lang="scss" scoped>
@import 'form-style';
</style>
